define("ralphandcelena/components/ll-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      goToMap() {
        window.open("https://goo.gl/maps/719tgpuwCbprRcEG7", "_blank");
      }
    },
    markerLocation: Object.freeze([53.0369114, -1.1405967])
  });
  _exports.default = _default;
});