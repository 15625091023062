define("ralphandcelena/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
      const lang = navigator.language || navigator.userLanguage;
      this.set('lang', lang.substr(0, 2));
    },
    collapsedMenu: true,
    actions: {
      toggleMenu() {
        this.toggleProperty('collapsedMenu');
      },
      toggleLang(lang) {
        this.set('lang', lang);
      }
    }
  });
  _exports.default = _default;
});