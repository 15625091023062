define("ralphandcelena/helpers/t", ["exports", "ralphandcelena/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.t = t;
  const i18n = _environment.default.APP.t;
  function t(params) {
    const string = params[0];
    const lang = params[1];
    if (string && lang) {
      return Ember.get(i18n, `${string}.${lang}`) || string;
    }
    return params;
  }
  var _default = Ember.Helper.helper(t);
  _exports.default = _default;
});