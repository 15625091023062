define("ralphandcelena/routes/application", ["exports", "ralphandcelena/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    actions: {
      didTransition() {
        if (_environment.default.environment === 'production' && window.location.protocol == 'http:') {
          window.location.protocol = 'https:';
        }
      }
    }
  });
  _exports.default = _default;
});