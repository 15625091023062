define("ralphandcelena/components/rsvp-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    name: null,
    email: null,
    areYouComing: null,
    accomodation: null,
    travel: null,
    areYouVegetarian: null,
    allergies: null,
    doYouHaveKids: null,
    babysitter: null,
    init() {
      this._super(...arguments);
      this.set('success', false);
    },
    hideSubmit: Ember.computed('areYouComing', function () {
      return this.get('areYouComing') === null || this.get('areYouComing') === undefined;
    }),
    actions: {
      submit() {
        if (this.get('areYouComing') === null || this.get('areYouComing') === undefined) {
          this.handleError('Are you coming?');
        } else {
          const data = {
            name: this.get('name'),
            email: this.get('email'),
            areYouComing: this.get('areYouComing'),
            accomodation: this.get('accomodation'),
            travel: this.get('travel'),
            areYouVegetarian: this.get('areYouVegetarian'),
            allergies: this.get('allergies'),
            doYouHaveKids: this.get('doYouHaveKids'),
            babysitter: this.get('babysitter')
          };
          const request = this.get('ajax').post('/api/rsvp/index.php', {
            data
          });
          request.then(_ref => {
            let {
              response
            } = _ref;
            return this.handleSuccess(response);
          }).catch(() => this.handleError('There was a problem with your request, please try again later or use the contact page'));
        }
        window.scrollTo(0, 0);
      }
    },
    handleSuccess() {
      this.set('success', true);
    },
    handleError(msg) {
      this.set('error', msg);
    }
  });
  _exports.default = _default;
});