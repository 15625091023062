define("ralphandcelena/templates/components/ll-amp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EwoH6QRq",
    "block": "{\"symbols\":[],\"statements\":[[0,\"&\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/ll-amp.hbs"
    }
  });
  _exports.default = _default;
});