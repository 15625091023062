define("ralphandcelena/templates/components/generic-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vvD/L/8y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\",true],[11,\"class\",[29,[\"masthead bg-\",[22,\"bg\"],\" \",[28,\"if\",[[24,[\"headerTitle\"]],\"has-title\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"headerTitle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"display-4 text-white has-shadow\"],[8],[1,[28,\"t\",[[24,[\"headerTitle\"]],[24,[\"lang\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[14,1,[[23,0,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/generic-layout.hbs"
    }
  });
  _exports.default = _default;
});