define("ralphandcelena/templates/components/ll-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "v0MqYe/Q",
    "block": "{\"symbols\":[\"layers\",\"marker\"],\"statements\":[[5,\"leaflet-map\",[],[[\"@center\",\"@zoom\"],[[22,\"markerLocation\"],13]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"tile\"]],[],[[\"@url\"],[\"https://tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey=66dc1f656adb4e48aa7f058e11cab45c\"]]],[0,\"\\n\\n  \"],[6,[23,1,[\"marker\"]],[],[[\"@location\",\"@onClick\"],[[22,\"markerLocation\"],[28,\"action\",[[23,0,[]],\"goToMap\"],null]]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"tooltip\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"tooltip-title\"],[8],[0,\"Sherwood Glade\"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/ll-map.hbs"
    }
  });
  _exports.default = _default;
});