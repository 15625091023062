define("ralphandcelena/mixins/process-options", ["exports", "ember-google-maps/mixins/process-options"], function (_exports, _processOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _processOptions.default;
    }
  });
});