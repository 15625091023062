define("ralphandcelena/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TK4B/ydv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ll-header\",null,[[\"collapsedMenu\",\"toggleLang\",\"toggleMenu\"],[[24,[\"collapsedMenu\"]],[28,\"action\",[[23,0,[]],\"toggleLang\"],null],[28,\"action\",[[23,0,[]],\"toggleMenu\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/application.hbs"
    }
  });
  _exports.default = _default;
});