define("ralphandcelena/templates/components/ll-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "76HcMmRG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"footer\",true],[10,\"class\",\"py-5 bg-black\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"m-0 text-center text-white small\"],[8],[0,\"© Ralph & Celena\"],[9],[0,\"\\n  \"],[9],[0,\"    \\n\"],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/ll-footer.hbs"
    }
  });
  _exports.default = _default;
});