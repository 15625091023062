define("ralphandcelena/templates/components/generic-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TvEQ+srp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row align-items-center\"],[8],[0,\"\\n      \"],[14,1,[[23,0,[]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/components/generic-section.hbs"
    }
  });
  _exports.default = _default;
});