define("ralphandcelena/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u9yFA83A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"masthead text-center text-white is-home\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"masthead-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"masthead-heading\"],[8],[0,\"Sarah + Mark\"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"masthead-subheading mb-0\"],[8],[1,[28,\"t\",[\"the date\",[24,[\"lang\"]]],null],false],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-primary btn-xl rounded-pill mt-4\",\"rsvp\"]],{\"statements\":[[0,\"        RSVP\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/index.hbs"
    }
  });
  _exports.default = _default;
});