define("ralphandcelena/templates/rsvp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N5meD5kM",
    "block": "{\"symbols\":[\"l\"],\"statements\":[[4,\"generic-layout\",null,[[\"bg\",\"headerTitle\"],[\"food\",\"titles.rsvp\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-5\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"display-5\"],[8],[1,[28,\"t\",[\"rsvp.are you coming\",[23,1,[\"lang\"]]],null],false],[9],[0,\"\\n\\n    \"],[1,[22,\"rsvp-form\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ralphandcelena/templates/rsvp.hbs"
    }
  });
  _exports.default = _default;
});